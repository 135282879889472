import React from "react"
import { colors, margins, fontSizes, breakpoint } from "../cms/style"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import dataState from "../constants/dataState.constants"
import { Link } from "gatsby"

import appcleverLogo from "../assets/brand-logos/_svg/appclever.svg"
import eceibLogo from "../assets/brand-logos/_svg/eceib.svg"
import kupDronaLogo from "../assets/brand-logos/_svg/kup-drona.svg"
import kursNaDronaLogo from "../assets/brand-logos/_svg/kurs-na-drona.svg"
import rdbMediaLogo from "../assets/brand-logos/_svg/rdb-media.svg"
import reklamaDlaBiznesuLogo from "../assets/brand-logos/_svg/ReklamaDlaBiznesu_logo.svg"
import rdbsocialsLogo from "../assets/rdb-socials/RDB Socials_logo.svg"
import rubyStudioLogo from "../assets/brand-logos/_svg/ruby-studio.svg"
import OklejanieLogo from "../assets/brand-logos/_svg/oklejaniefloty.svg"
// import wearIdLogo from '../assets/brand-logos/_svg/wearid.svg'
import zadronowaniLogo from "../assets/brand-logos/_svg/zadronowani.svg"
import zagrawerowaniLogo from "../assets/brand-logos/_svg/zagrawerowani.svg"
// import zahaftowaniLogo from '../assets/brand-logos/_svg/zahaftowani.svg'

import parkerLogo from "../assets/brand-logos/_svg/parker.svg"
import pieczatkiLogo from "../assets/brand-logos/_svg/pieczatkinajutro-poziom.svg"
import watermanLogo from "../assets/brand-logos/_svg/waterman.svg"

export default function S3({ data, activeSection }) {
  const [scrollHeight, setScrollHeight] = React.useState(0)
  const [topPosition, setTopPosition] = React.useState(0)

  React.useEffect(() => {
    if (Array.isArray(data?.brands) && data.brands.length > 0) {
      const handleScroll = (e) => {
        const s3Element = document.getElementById("s3");
        if (!s3Element) return; // Exit if the element doesn't exist

        const topPos =
          ((window.pageYOffset - scrollHeight + window.outerHeight) /
            s3Element.offsetHeight) *
          100;
        if (topPos < 0) setTopPosition("-20%");
        else if (topPos >= 0 && topPos < 70) setTopPosition(topPos - 20 + "%");
        else if (topPos >= 70 && topPos < 72) setTopPosition(topPos - 19 + "%");
        else if (topPos >= 72 && topPos < 74) setTopPosition(topPos - 18 + "%");
        else if (topPos >= 74 && topPos < 76) setTopPosition(topPos - 16 + "%");
        else if (topPos >= 76 && topPos < 78) setTopPosition(topPos - 14 + "%");
        else if (topPos >= 78 && topPos < 80) setTopPosition(topPos - 12 + "%");
        else if (topPos >= 80 && topPos < 82) setTopPosition(topPos - 10 + "%");
        else if (topPos >= 82 && topPos < 84) setTopPosition(topPos - 8 + "%");
        else if (topPos >= 84 && topPos < 86) setTopPosition(topPos - 7 + "%");
        else if (topPos >= 86 && topPos < 88) setTopPosition(topPos - 6 + "%");
        else if (topPos >= 88 && topPos < 90) setTopPosition(topPos - 5 + "%");
        else if (topPos >= 90 && topPos < 95) setTopPosition(topPos - 4 + "%");
        else if (topPos >= 95 && topPos < 99) setTopPosition(topPos - 3 + "%");
        else if (topPos >= 99) setTopPosition("100%");
      };
  
      window.addEventListener("scroll", handleScroll);
      setScrollHeight(document.getElementById("s3").offsetTop);
  
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [scrollHeight, data?.brands]);

  // React.useEffect(() => {
  //   window.addEventListener("scroll", handleScroll)
  //   setScrollHeight(document.getElementById("s3").offsetTop)

  //   function handleScroll(e) {
  //     const topPos =
  //       ((window.pageYOffset - scrollHeight + window.outerHeight) /
  //         document.getElementById("s3").offsetHeight) *
  //       100
  //     if (topPos < 0) setTopPosition("-20%")
  //     if (topPos >= 0 && topPos < 70) setTopPosition(topPos - 20 + "%")
  //     if (topPos >= 70 && topPos < 72) setTopPosition(topPos - 19 + "%")
  //     if (topPos >= 72 && topPos < 74) setTopPosition(topPos - 18 + "%")
  //     if (topPos >= 74 && topPos < 76) setTopPosition(topPos - 16 + "%")
  //     if (topPos >= 76 && topPos < 78) setTopPosition(topPos - 14 + "%")
  //     if (topPos >= 78 && topPos < 80) setTopPosition(topPos - 12 + "%")

  //     if (topPos >= 80 && topPos < 82) setTopPosition(topPos - 10 + "%")
  //     if (topPos >= 82 && topPos < 84) setTopPosition(topPos - 8 + "%")
  //     if (topPos >= 84 && topPos < 86) setTopPosition(topPos - 7 + "%")
  //     if (topPos >= 86 && topPos < 88) setTopPosition(topPos - 6 + "%")
  //     if (topPos >= 88 && topPos < 90) setTopPosition(topPos - 5 + "%")
  //     if (topPos >= 90 && topPos < 95) setTopPosition(topPos - 4 + "%")
  //     if (topPos >= 95 && topPos < 99) setTopPosition(topPos - 3 + "%")
  //     if (topPos >= 99) setTopPosition("100%")
  //   }

  //   return () => window.removeEventListener("scroll", handleScroll)
  // }, [scrollHeight])

  const imgQuery = useStaticQuery(graphql`
    query {
      # IMAGES
      rdb: file(
        relativePath: { eq: "brands-img/rdb-studio/na-strone-08.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      reklamadlabiznesu: file(
        relativePath: { eq: "brands-img/reklamadlabiznesu/na-strone-11.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      socials: file(
        relativePath: {
          eq: "brands-img/rdb-socials/pile-3d-popular-social-media-logos.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      zagrawerowani: file(
        relativePath: { eq: "brands-img/zagrawerowani/na-strone-06.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      parker: file(relativePath: { eq: "brands-img/parker/parker1.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pieczatki: file(
        relativePath: { eq: "brands-img/pieczatki-na-jutro/na-strone-01.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      waterman: file(
        relativePath: { eq: "brands-img/waterman/waterman1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      rubyStudio: file(relativePath: { eq: "brands-img/ruby-studio/5.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      zadronowani: file(
        relativePath: { eq: "brands-img/zadronowani/IMG_1206.JPG" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kursNaDrona: file(
        relativePath: {
          eq: "brands-img/kurs-na-drona/3F61EC19-D68E-4A37-B76D-4AA987D88996.JPG"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kupDrona: file(
        relativePath: { eq: "brands-img/kup-drona/IMG_1157.JPG" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      appClever: file(
        relativePath: { eq: "brands-img/webclever/na-strone-10.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eceib: file(relativePath: { eq: "brands-img/eceib/140.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const imgReklamaImageFluids = [
    imgQuery.reklamadlabiznesu?.childImageSharp.fluid,
    // imgQuery.socials?.childImageSharp.fluid,
    imgQuery.rdb.childImageSharp?.fluid,
    // imgQuery.rubyStudio?.childImageSharp.fluid,
  ]
  const imgDronyImageFluids = [
    imgQuery.zadronowani.childImageSharp.fluid,
    imgQuery.kursNaDrona.childImageSharp.fluid,
    imgQuery.kupDrona.childImageSharp.fluid,
  ]
  const imgBiznesImageFluids = [
    imgQuery.eceib?.childImageSharp.fluid,
    imgQuery.appClever?.childImageSharp.fluid,
    imgQuery.zagrawerowani?.childImageSharp.fluid,
    // imgQuery.zahaftowani?.childImageSharp.fluid,
    // imgQuery.wearId?.childImageSharp.fluid,
    imgQuery.parker?.childImageSharp.fluid,
    imgQuery.pieczatki?.childImageSharp.fluid,
    imgQuery.waterman?.childImageSharp.fluid,
  ]
  function whichImages(index) {
    switch (activeSection) {
      case dataState.reklama:
        return imgReklamaImageFluids[index]

      case dataState.doradztwo:
        return imgDronyImageFluids[index]

      case dataState.biznes:
        return imgBiznesImageFluids[index]
    }
  }

  const svgReklamaLogos = [
    reklamaDlaBiznesuLogo,
    // rdbsocialsLogo,
    rdbMediaLogo,
    OklejanieLogo
  ]
  const svgDronyLogos = [zadronowaniLogo, kursNaDronaLogo, kupDronaLogo]
  const svgBiznesLogos = [
    eceibLogo,
    appcleverLogo,
    zagrawerowaniLogo,
    // zahaftowaniLogo,
    // wearIdLogo,
    parkerLogo,
    pieczatkiLogo,
    watermanLogo,
  ]
  function whichLogo(index) {
    switch (activeSection) {
      case dataState.reklama:
        return svgReklamaLogos[index]

      case dataState.doradztwo:
        return svgDronyLogos[index]

      case dataState.biznes:
        return svgBiznesLogos[index]
    }
  }
  if (Array.isArray(data?.brands) && data.brands.length > 0) {
    return (
      <section id="s3" css={styles.mainSection}>
        <div>
          <h3>Nasze marki</h3>
        </div>
        <div css={styles.parallax} style={{ top: topPosition }}>
          <h6>{data.headerDiv.header}</h6>
        </div>
  
        {data.brands.map((el, index) => (
          <div key={index} css={styles.itemChild}>
            <div
              css={{
                [breakpoint.sm]: {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                },
              }}
            >
              <div
                css={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "160px",
                  height: "160px",
                  marginRight: 48,
                  [breakpoint.sm]: {
                    marginRight: 16,
                    width: 200,
                    height: 200,
                  },
                }}
              >
                <img src={whichLogo(index)} alt="" css={{ width: "100%" }} />
              </div>
            </div>
  
            <div
              css={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
                maxWidth: "700px",
                marginRight: "auto",
                [breakpoint.sm]: {
                  "h4, h5": { display: "none" },
                  margin: "16px 0",
                },
              }}
            >
              <h4>{el.header}</h4>
                {/* <Link
                  css={{ color: "inherit", textDecoration: "none" }}
                  to={el.url ? `${el.url}` : null}
                >
                  {el.header}
                </Link> */}
              <h5>{el.subheader}</h5>
              <p>{el.text}</p>
              <div css={sButtons}>
                {el.internal && (
                  <Link
                    css={{ backgroundColor: colors.brands.reklama }}
                    to={el.internal}
                  >
                    Dowiedz się więcej
                  </Link>
                )}
                {el.external && (
                  <a
                    css={{ backgroundColor: colors.brands.biznes }}
                    href={el.external}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Odwiedź witrynę marki
                  </a>
                )}
              </div>
            </div>
  
            {/* <div id="viewpager">
              <div
                css={{
                  position: "relative",
                  width: "320px",
                  height: "160px",
                  borderRadius: "16px",
                  overflow: "hidden",
                  marginRight: 20,
                  [breakpoint.sm]: {
                    margin: "0 auto",
                    maxWidth: "100%",
                    height: "auto",
                  },
                }}
              >
                {el.url && (<Link to={el.url}>
                  <Img fluid={whichImages(index)} />
                </Link>)}
              </div>
            </div> */}
          </div>
        ))}
      </section>
    );
  } else {
    return null;
  }
}

const sButtons = {
  display: "flex",
  padding: "1.5rem 0",
  a: {
    marginRight: "1.5rem",
    width: "max-content",
    fontWeight: "bold",
    textTransform: "uppercase",
    letterSpacing: "0.05em",
    fontSize: "0.5rem",
    color: "white",
    padding: "0.5rem 0.75rem",
    borderRadius: "0.5rem",
    fontFamily: "Montserrat",
    textDecoration: "none",
    transition:
      "transform 0.2s ease-out, box-shadow 0.2s ease-out, filter 0.2s ease-out",
    "&:hover": {
      transform: "scale(1.33)",
      boxShadow: "2px 2px 8px rgb(0 0 0 / 8%)",
      filter: "brightness(1.25)",
    },
  },
  [breakpoint.xs]: {
    flexDirection: "column",
    padding: "1.5rem 0 0",
    a: {
      width: "100%",
      marginRight: 0,
      textAlign: "center",
      padding: "0.57rem 1rem",
      fontSize: "0.675rem",
      marginBottom: "1rem",
      "&:hover": {
        transform: "scale(1)",
      },
    },
  },
  [breakpoint.sm]: {
    justifyContent: "center",
    alignItems: "center",
  },
  [breakpoint.md]: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
}

const styles = {
  mainSection: {
    padding: margins.sectionsX.xl,
    position: "relative",

    h3: {
      color: colors.headers.lightBlack,
      fontSize: fontSizes.fz2.xl,
      letterSpacing: "-3px",
      marginBottom: 60,
    },

    [breakpoint.lg]: {
      padding: margins.sectionsX.lg,

      h3: {
        fontSize: fontSizes.fz2.lg,
      },
    },

    [breakpoint.md]: {
      padding: margins.sectionsX.md,

      h3: {
        fontSize: fontSizes.fz2.md,
      },
    },

    [breakpoint.sm]: {
      padding: margins.sectionsX.sm,
      paddingRight: 48,

      h3: {
        fontSize: fontSizes.fz2.sm,
      },
    },

    [breakpoint.xs]: {
      padding: margins.sectionsX.xs,
      paddingRight: 48,

      h3: {
        fontSize: fontSizes.fz2.xs,
      },
    },
  },

  itemChild: {
    display: "flex",
    maxWidth: 1480,
    marginBottom: 80,
    // margin: '0 auto 80px',

    h4: {
      fontSize: fontSizes.fz3.xl,
      marginBottom: 8,
      color: colors.headers.black,
    },
    h5: {
      fontSize: fontSizes.fz4.xl,
      marginBottom: 16,
      color: colors.headers.grey,
    },
    p: {
      fontSize: fontSizes.fz5.xl,
    },

    [breakpoint.lg]: {
      h4: {
        fontSize: fontSizes.fz3.lg,
      },

      h5: {
        fontSize: fontSizes.fz4.lg,
      },

      p: {
        fontSize: fontSizes.fz5.lg,
      },
    },
    [breakpoint.md]: {
      width: "100%",

      h4: {
        fontSize: fontSizes.fz3.md,
      },

      h5: {
        fontSize: fontSizes.fz4.md,
      },

      p: {
        fontSize: fontSizes.fz5.md,
      },
    },
    [breakpoint.sm]: {
      flexDirection: "column",

      ">div": { width: "100%" },

      h4: {
        fontSize: fontSizes.fz3.sm,
        marginBottom: 0,
      },

      h5: {
        fontSize: fontSizes.fz4.sm,
      },

      p: {
        fontSize: fontSizes.fz5.sm,
      },
    },
    [breakpoint.xs]: {
      h4: {
        fontSize: fontSizes.fz3.xs,
      },

      h5: {
        fontSize: fontSizes.fz4.xs,
      },

      p: {
        fontSize: fontSizes.fz5.xs,
      },
    },
  },

  parallax: {
    position: "absolute",
    top: "-50%",
    left: "50%",
    transform: "translate(-50%, -120px)",
    zIndex: -5,
    transition: "1.5s ease-out",
    h6: {
      fontSize: fontSizes.fz0.xl,
      lineHeight: 1,
      color: "#fff",
      textShadow: "0 0 20px #00000007",
      fontWeight: 700,
      textTransform: "uppercase",

      [breakpoint.lg]: {
        fontSize: fontSizes.fz0.lg,
      },
      [breakpoint.md]: {
        fontSize: fontSizes.fz0.md,
      },
      [breakpoint.sm]: {
        fontSize: fontSizes.fz0.sm,
      },
      [breakpoint.xs]: {
        fontSize: fontSizes.fz0.xs,
      },
    },

    [breakpoint.lg]: {
      transform: "translate(-50%, -77px)",
    },
    [breakpoint.md]: {
      transform: "translate(-50%, -77px)",
    },
    [breakpoint.sm]: {
      transform: "translate(-50%, -43px)",
    },
    [breakpoint.xs]: {
      transform: "translate(-50%, -29px)",
    },
  },
}
